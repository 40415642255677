import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';

import { APPLE_LOGIN_CLIENT_ID } from '../../../constants/api';
import { SocialProvider } from '../../../utils/socialLoginUtils';

import { SocialUser } from './SocialUser';

const USER_NAME_IF_NO_NAME_PROVIDED_BY_APPLE = 'noname';

export const getOptions = (redirectURI: string): SignInWithAppleOptions => {
  return {
    clientId: APPLE_LOGIN_CLIENT_ID,
    redirectURI,
    scopes: 'email name',
    state: 'state',
    nonce: 'nonce',
  };
};

export const signInApple = async (): Promise<SocialUser> => {
  const redirectURI = `${window.location.origin}/login`;
  const options = getOptions(redirectURI);
  console.log(options);

  try {
    const { response }: SignInWithAppleResponse =
      await SignInWithApple.authorize(options);

    console.log(response);
    const user: SocialUser = {
      provider: SocialProvider.apple,
      name: getUserName(response.givenName, response.familyName),
      token: response.identityToken,
      serverAuthCode: response.authorizationCode,
    };

    return user;
  } catch (err) {
    console.error('Apple sign-in error:', err);
    throw err;
  }
};

const getUserName = (
  givenName: string | null,
  familyName: string | null
): string => {
  if (givenName && familyName) {
    return `${givenName} ${familyName}`;
  } else if (givenName) {
    return givenName;
  } else if (familyName) {
    return familyName;
  }
  return USER_NAME_IF_NO_NAME_PROVIDED_BY_APPLE;
};
